import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/popper.js.org/popper.js.org/src/components/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "typings",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#typings",
        "aria-label": "typings permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Typings`}</h1>
    <p>{`Popper comes with type definitions for both Flow and TypeScript.`}</p>
    <p>{`The project is type checked by Flow, and type definitions for TypeScript are
automatically generated from its source code.`}</p>
    <p>{`Note, type definitions don't strictly follow SemVer, since any type change can
be a breaking change, but type systems evolve very quickly and adjustments are
constantly required to keep up with them.`}</p>
    <x-ad />
    <h2 {...{
      "id": "common-flow-and-typescript",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#common-flow-and-typescript",
        "aria-label": "common flow and typescript permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Common (Flow and TypeScript)`}</h2>
    <p>{`This section covers common knowledge applicable to both Flow and TypeScript, for
sake of simplicity the examples are written in Flow, but they should work as-is
after the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`// @flow`}</code>{` header has been removed.`}</p>
    <h3 {...{
      "id": "modifiers-type-checking",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#modifiers-type-checking",
        "aria-label": "modifiers type checking permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Modifiers Type Checking`}</h3>
    <p><code parentName="p" {...{
        "className": "language-text"
      }}>{`createPopper`}</code>{`, by default, works in a loose-type mode, that means the modifiers
are not strictly type-checked.`}</p>
    <p>{`If you'd like to opt-in to the strict-mode, you can do the following:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// @flow`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createPopper `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` type `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` StrictModifiers `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

createPopper`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`StrictModifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`referenceElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popperElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`If you'd like to define custom modifiers, you will need to add them to the list:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "js"
    }}><pre parentName="div" {...{
        "className": "language-js"
      }}><code parentName="pre" {...{
          "className": "language-js"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`// @flow`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` createPopper `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` type `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` StrictModifiers`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` Modifier `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

type CustomModifier `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` Modifier`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span><span parentName="code" {...{
            "className": "token string"
          }}>{`'custom'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{` customOption`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` boolean `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
type ExtendedModifiers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` StrictModifiers `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`|`}</span>{` $Shape`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`CustomModifier`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

createPopper`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`<`}</span>{`ExtendedModifiers`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`>`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`referenceElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` popperElement`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` options`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <h2 {...{
      "id": "flow",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#flow",
        "aria-label": "flow permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Flow`}</h2>
    <p>{`Rather than exporting limited type definitions, we link to the Flow-typed source
files from the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`dist/umd`}</code>{` folder.`}</p>
    <p>{`Flow will automatically read the exposed types, so you don't need to change any
configuration in your project to take benefit from Popper typings.`}</p>
    <p>{`You may also decide to import a specific module from the library, by doing so,
the module types will be automatically imported with it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "flow"
    }}><pre parentName="div" {...{
        "className": "language-flow"
      }}><code parentName="pre" {...{
          "className": "language-flow"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` hideModifier `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core/lib/modifiers/hide'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`It's although reccomended to add the following lines to your `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.flowconfig`}</code>{` file
in order to instruct Flow not to report type errors originating from your
`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`node_modules`}</code>{` folder. Type definitions exposed by the modules will still be
used, but their errors, if any, will be ignored.`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "ini"
    }}><pre parentName="div" {...{
        "className": "language-ini"
      }}><code parentName="pre" {...{
          "className": "language-ini"
        }}><span parentName="code" {...{
            "className": "token selector"
          }}>{`[declarations]`}</span>{`
<PROJECT_ROOT>/node_modules`}</code></pre></div>
    <h2 {...{
      "id": "typescript",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#typescript",
        "aria-label": "typescript permalink",
        "className": "gatsby-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`TypeScript`}</h2>
    <p>{`Generating TypeScript type definitions from Flow makes it possible to type check
the project with stricter rules, providing at the same time type definitions for
users of other type system.`}</p>
    <p>{`Type definition interfaces for the two type systems are inherently identical,
but we can't guarantee the same level of type coverage for TypeScript
definitions, due to the intrinsic differences between Flow and TypeScript.`}</p>
    <p>{`TypeScript `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`.d.ts`}</code>{` files are colocated in the `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`lib/`}</code>{` folder, together with the
corresponding JavaScript modules, an `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`index.d.ts`}</code>{` file located on the root of
the repository allows the TypeScript compiler to locate them, no additional
configuration is needed to consume the typings.`}</p>
    <p>{`You may also decide to import a specific module from the library, by doing so,
the module types will be automatically imported with it:`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "typescript"
    }}><pre parentName="div" {...{
        "className": "language-typescript"
      }}><code parentName="pre" {...{
          "className": "language-typescript"
        }}><span parentName="code" {...{
            "className": "token keyword"
          }}>{`import`}</span>{` hideModifier `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`from`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'@popperjs/core/lib/modifiers/hide'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      